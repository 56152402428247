import { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Layout from "../components/Layout";
import axios from "axios";
import "flatpickr/dist/flatpickr.min.css"; // Import Flatpickr styles
import flatpickr from "flatpickr";
import Lottie from "react-lottie";
import noDatafound from "../assets/images/Animation - 1721025848612.json";
import { msiAPi } from "../Data/api";

const Mis = () => {
  const flatpickrRef = useRef(null);
  const [selectedDates, setSelectedDates] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  });
  const [staticData, setStaticData] = useState([]);
  const [error, setError] = useState(null); // Error state for handling errors
  console.log("data", staticData);

  useEffect(() => {
    flatpickr(flatpickrRef.current, {
      mode: "range",
      dateFormat: "Y-m-d",
      defaultDate: [selectedDates.startDate, selectedDates.endDate],
      onChange: (selectedDates) => {
        if (Array.isArray(selectedDates) && selectedDates.length === 2) {
          setSelectedDates({
            startDate: selectedDates[0],
            endDate: selectedDates[1],
          });
        }
      },
    });
  }, []);

  useEffect(() => {
    if (selectedDates.startDate && selectedDates.endDate) {
      fetchData(selectedDates.startDate, selectedDates.endDate);
    }

    // Set up an interval to refresh data every 5 minutes (300000 milliseconds)
    const intervalId = setInterval(() => {
      fetchData(selectedDates.startDate, selectedDates.endDate, false);
    }, 1000); // Refresh every 5 minutes

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [selectedDates]);

  const fetchData = async (startDate, endDate, showLoading = true) => {
    try {
      const response = await axios.post(
        `${msiAPi}?from=${startDate.toISOString().split("T")[0]}&to=${endDate
          .toISOString()
          .split("T")[0]}`
      );
      console.log("API response:", response.data.results);

      if (Array.isArray(response.data.results)) {
        const sanitizedData = response.data.results.map((item) => {
          return Object.fromEntries(
            Object.entries(item).map(([key, value]) => [key, value === null ? 0 : value])
          );
        });
        setStaticData(sanitizedData);
      } else if (typeof response.data === "object" && response.data !== null) {
        const sanitizedData = Object.fromEntries(
          Object.entries(response.data).map(([key, value]) => [key, value === null ? 0 : value])
        );
        setStaticData([sanitizedData]);
      } else {
        console.error("Error: Expected an array or object from API");
        setStaticData([]);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setError("Error fetching data. Please try again later.");
    }
  };

  return (
    <Layout>
      <div className="max-w-full mx-auto m-1 py-2 border-2 border-gray-200 bg-white">
        <div className="mx-auto">
          <div className="rounded">
            <h1 className="text-2xl text-violet-500 font-bold text-center p-4">
              MIS REPORT
            </h1>
            <div className="flex justify-center items-center text-center space-x-5 mt-5">
              <input
                className="w-72 px-4 py-3 text-lg text-gray-800 placeholder-gray-500 border border-gray-300 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 ease-in-out"
                ref={flatpickrRef}
                placeholder="Click to Select Date"
              />
            </div>
          </div>
          <div className="flex justify-center w-full max-w-[330px] md:max-w-[1000px] py-10 overflow-x-auto">
            {staticData.length <= 0 ? (
              <div className="flex flex-col items-center justify-center">
                <Lottie
                  options={{
                    autoplay: true,
                    animationData: noDatafound,
                  }}
                  style={{ width: "20%", height: "auto" }}
                />
                <div className="text-center font-semibold">No data Found</div>
              </div>
            ) : (
              <div className="p-4 flex justify-center w-full overflow-x-auto">
                <div className="w-[300px] ml-9 md:w-[1000px]">
                  <DataTable value={staticData} paginator rows={5} className="mt-3 w-full text-center items-center border-2">
                    <Column
                      className="border border-gray-300 px-4 py-2 text-left"
                      field="mis_date"
                      header="Mis date"
                      headerClassName="bg-gray-100 text-gray-800 font-semibold text-left p-2"
                    />
                    <Column
                      className="border border-gray-300 px-4 py-2 text-left"
                      field="subscriptions"
                      header="Subscriptions"
                    />
                    <Column
                      className="border border-gray-300 px-4 py-2 text-left"
                      field="renewals"
                      header="Renewals"
                    />
                    <Column
                      className="border border-gray-300 px-4 py-2 text-left"
                      field="unsubscriptions"
                      header="Unsubscriptions"
                    />
                    <Column
                      className="border border-gray-300 px-4 py-2 text-left"
                      field="sub_revenue"
                      header="Sub revenue"
                    />
                    <Column
                      className="border border-gray-300 px-4 py-2 text-left"
                      field="ren_revenue"
                      header="Ren revenue"
                    />
                    <Column
                      className="border border-gray-300 px-4 py-2 text-left"
                      field="total_revenue"
                      header="Total Revenue"
                    />
                  </DataTable>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Mis;
