import React, { useState } from "react";
import axios from "axios";
import Layout from "../components/Layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import {userlogsapi} from "../Data/api"
const UserLogs = () => {
  const [msisdn, setMsisdn] = useState("");
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setResult(null);
    setLoading(true); // Show loading spinner

    try {
      const response = await axios.post(userlogsapi, {
        msisdn,
      });
      setResult(response.data.result); // Display the result
    } catch (err) {
      console.error("API Error:", err);
      setError("Error fetching data. Please try again.");
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  return (
    <Layout>
      <div className="flex justify-center items-center  bg-gray-100">
        <div className="w-full max-w-[330px] md:max-w-[1000px] py-10 overflow-x-auto">
          <h1 className="text-3xl font-semibold text-gray-800 text-center mb-8">
            User Logs
          </h1>
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <input
              type="number"
              placeholder="Enter your MSISDN"
              value={msisdn}
              onChange={(e) => setMsisdn(e.target.value)}
              className="border-2 border-blue-500 rounded-md h-12 text-center px-4 w-full max-w-sm mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-8 rounded-full shadow-md transition-all focus:outline-none focus:ring-4 focus:ring-blue-400"
            >
              Submit
            </button>
          </form>

          {/* Show loading spinner */}
          {loading && (
            <div className="flex justify-center items-center mt-6">
              <div className="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-blue-600"></div>
            </div>
          )}

          {/* Display error message */}
          {error && <p className="text-red-500 mt-4">{error}</p>}

          {/* Display table if there is data */}
          {result && result.length > 0 && (
            <div className="mt-8 w-full overflow-x-auto">
              <DataTable value={result} className="min-w-full border-2">
              <Column
  field="subscription_date"
  header="Subscription Date"
  body={(data) => {
    const date = new Date(data.subscription_date);
    return date.toLocaleDateString(); // This will display only the date
  }}
></Column>
                <Column field="billing_count" header="Billing Count"></Column>
                <Column
                  field="charged_amount"
                  header="Charged Amount"
                  body={(data) =>
                    data.charged_amount !== null &&
                    data.charged_amount !== undefined
                      ? data.charged_amount
                      : "---"
                  }
                ></Column>
                <Column
                  field="unsubscribe_date"
                  header="Unsubscribe Date"
                  body={(data) =>
                    data.unsubscribe_date
                      ? new Date(data.unsubscribe_date).toLocaleString()
                      : "---"
                  }
                ></Column>
              </DataTable>
            </div>
          )}

          {/* If no result found */}
          {result && result.length === 0 && !loading && (
            <p className="text-gray-500 mt-4 text-center">No records found.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default UserLogs;
