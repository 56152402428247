import React, { useState } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Blacklist = () => {
  const [msisdn, setMsisdn] = useState('');

  const handleSubmit = async () => {
    if (!msisdn) {
      toast.error('Please enter a valid MSISDN.');
      return;
    }

    try {
      const response = await axios.post('http://localhost:9090/api/dnd', { msisdn });
console.log(response.data,"hhhhh")
      if (response.status === 200) {
        toast.success('Number successfully blacklisted!');
      } else {
        toast.error('Failed to blacklist the number.');
      }
    } catch (error) {
      console.log(error,"error")
      toast.error(error?.response?.data?.error?.message || error?.message || 'Error');
    }
  };

  return (
    <Layout>
      <div className='flex justify-center items-center'>
        <div className='bg-white shadow-lg rounded-lg p-8'>
          <h1 className='text-2xl font-semibold text-gray-700 text-center mb-6'>
            Blacklist the number
          </h1>
          <div className='flex flex-col items-center space-y-4'>
            <input
              type="number"
              placeholder='Enter your MSISDN'
              className='border-2 border-blue-400 rounded-md h-12 text-center px-4 w-64 focus:outline-none focus:ring-2 focus:ring-blue-500'
              value={msisdn}
              onChange={(e) => setMsisdn(e.target.value)}
            />
            <button
              className='bg-red-500 hover:bg-red-600 text-white py-2 px-6 rounded-full shadow-md transition-all'
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Blacklist;
