import Layout from './components/Layout'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import DailuRevenue from "./pages/DailuRevenue"
import Login from './pages/Login'
import Mis from './pages/MIS'
import Hourly from "./pages/Hourly"
import Blacklist from './pages/Blacklist'
import Unsub from './pages/Unsub'
import Log from './pages/Log'
import UserLogs from "./pages/UserLogs"
import PrivateRoute from './pages/PrivateRoute'
import Trend from './pages/Trend'
function App() {
    return (
        <BrowserRouter>
       
                <Routes>
                
                    <Route path='/' element={<Login />} />
              
                    <Route path='/Dailylogs' element={  <PrivateRoute> <DailuRevenue /></PrivateRoute>} />
                    <Route path='/mis-data' element={ <PrivateRoute><Mis /></PrivateRoute>} />
                    <Route path='/one_hour' element={ <PrivateRoute><Hourly /></PrivateRoute>} />
                    <Route path='/black-list' element={ <PrivateRoute><Blacklist /></PrivateRoute>} />
                    <Route path='/unsub' element={<PrivateRoute><Unsub /></PrivateRoute>} />
                    <Route path='/logout' element={<PrivateRoute><Log /></PrivateRoute>} />
                    <Route path='/userLogs' element={ <PrivateRoute><UserLogs /></PrivateRoute>} />
                    <Route path='/hourly-trend' element={ <PrivateRoute><Trend /></PrivateRoute>} />
                
                
                </Routes>
        
        </BrowserRouter>

        
        
    )
}

export default App
