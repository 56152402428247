import React from 'react'
import Toggle from './ThemeToggle'

const Navbar = () => {
  return (
    <nav className='bg-gray-100 shadow-lg border-gray-900 mx-2 px-2 py-4 rounded dark:bg-gray-700'>
      <div className='container flex justify-between items-center mx-auto pt-1'>
        <div className='flex items-center shadow-2xl mx-auto'>
          <span className='text-xl font-bold  whitespace-nowrap dark:text-white '>
          Kiddocraze-Visiontrek-ken
          </span>
        </div>

        {/* <div className='flex justify-end pr-4'>
          <Toggle />
        </div> */}
      </div>
    </nav>
  )
}

export default Navbar
