import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const PrivateRoute = ({ children }) => {
  const userName = Cookies.get('userName');
  const password = Cookies.get('password');
  if (userName && password) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
