// const baseURL = `http://localhost:5580/`;
// const baseURL = `http://192.168.1.31:5580/`;
// const baseURL=`http://192.168.43.218:5580/`;
// const baseURL=`http://88.99.5.236:9090/`;
// const baseURL=`http://localhost:9090/`;
const baseURL=`/`;
// const baseURL=`https://dashboard.wheel2fortune.com/`;

// const baseURL = `/`;
export { baseURL };

 const TrafficApi =`${baseURL}api/hourly-traffic`;
 export {TrafficApi}

  const subDataHourly =`${baseURL}api/hourly-data-only-sub`;
  export{subDataHourly}

const Hourlyapi = `${baseURL}api/hourly-data`;
export { Hourlyapi };

const dailyapi = `${baseURL}api/daily`;
export { dailyapi };

const msiAPi = `${baseURL}api/mis`;
export { msiAPi };

const specificApi = `${baseURL}api/fetch-mis/specific`;
export {specificApi};

const checkingLogs = `${baseURL}api/fetch-mis`
export {checkingLogs}

const CheckHourlyLogsApi =`${baseURL}api/fetch-hourly-logs`
export {CheckHourlyLogsApi}

const CheckDailyBillingLogsApi =`${baseURL}api/fetch-billing-logs`
export {CheckDailyBillingLogsApi}
const SubscriptionApi = `${baseURL}api/upload/sub`
export {SubscriptionApi}
const SubscriptionBaseInfoApi =`${baseURL}api/upload/sub/base-info`
export {SubscriptionBaseInfoApi}
const UnSubscriptionApi =`${baseURL}api/upload/unsub`
export {UnSubscriptionApi}
const UnsubSingleUSer =`${baseURL}api/upload/unsub/single`
export {UnsubSingleUSer}
const SingleDNDSpecificLogs =`${baseURL}api/fetch-mis/specific-dnd`
export {SingleDNDSpecificLogs}

 const userlogsapi =`${baseURL}api/userlogs`
 export {userlogsapi}

 const trendApi =`${baseURL}api/fetch-hourly-trend`
 export {trendApi}