import React, { useState } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Unsub = () => {
  const [msisdn, setMsisdn] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await axios.post('http://88.99.5.236:2425/deactivate-user', {
        msisdn: msisdn
      });
    console.log("res",response.data)
      if (response.data.result === 1) {
        toast.success('Successfully unsubscribed!', {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error('Failed to unsubscribe. Please try again.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Layout>
      <div className='flex justify-center items-center'>
        <div className='bg-white shadow-lg rounded-lg p-8'>
          <h1 className='text-2xl font-semibold text-gray-700 text-center mb-6'>
            Unsubscribe from Service
          </h1>
          <div className='flex flex-col items-center space-y-4'>
            <input
              type="number"
              placeholder='Enter your MSISDN'
              value={msisdn}
              onChange={(e) => setMsisdn(e.target.value)}
              className='border-2 border-blue-400 rounded-md h-12 text-center px-4 w-64 focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
            <button
              onClick={handleSubmit}
              className='bg-red-500 hover:bg-red-600 text-white py-2 px-6 rounded-full shadow-md transition-all'
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Unsub;
