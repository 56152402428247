import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Hourlyapi, subDataHourly, TrafficApi } from "../Data/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the datepicker styles
import moment from "moment"; // Import moment.js

const Hourly = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Set current date by default
  const [isPaid, setIsPaid] = useState(false);
  const [traffic, setTraffic] = useState([]);

  const handleToggle = () => {
    setIsPaid(!isPaid); // Toggle between REN/PAID and SUB
  };

  useEffect(() => {
    getData(); // Fetch data whenever selectedDate or isPaid changes

    // Set up auto-refresh to refresh data every 5 minutes (300000 ms)
    const interval = setInterval(() => {
      getData(false); // Refresh data without showing the loading state
    }, 1000); // Adjust the time as needed

    // Clean up the interval when component unmounts
    return () => clearInterval(interval);
  }, [selectedDate, isPaid]);

  const getData = async (showLoading = true) => {
    if (showLoading) setLoading(true);
    setError(null);

    try {
      // Format the selected date using moment.js
      const formattedDate = moment(selectedDate).format("YYYY-MM-DD");

      let res;
      if (isPaid) {
        // Call the Hourlyapi if REN/PAID is selected
        res = await axios.post(Hourlyapi, { date: formattedDate });
      } else {
        // Call the subDataHourly if SUB is selected
        res = await axios.post(subDataHourly, { date: formattedDate });
      }

      if (res.data && res.data.result) {
        setData(res.data.result);
      } else {
        setData([]);
      }
    } catch (error) {
      setError("Error fetching data. Please try again later.");
      setData([]);
    } finally {
      if (showLoading) setLoading(false);
    }
  };

  useEffect(() => {
    // Function to fetch traffic data from the API
    const fetchTrafficData = async () => {
      try {
        const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
        const response = await axios.post(TrafficApi, {
          date: formattedDate,
        });
        setTraffic(response.data.result); // Store the response data in state
      } catch (error) {
        console.error("Error fetching traffic data:", error);
      }
    };

    fetchTrafficData(); // Call the function when the component is mounted
  }, [selectedDate]);

  return (
    <Layout>
      <div className="flex bg-white flex-col items-center">
        <div className="text-3xl md:text-2xl font-extrabold p-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-600 tracking-wide">
          Hourly Logs
        </div>

        {/* Date picker for selecting the date */}
        <div className="flex justify-between gap-3 m-2 p-2">
          <div className=" items-center justify-center flex py-2">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="yyyy-MM-dd"
              className="w-full p-2 text-gray-700 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-200 text-center justify-center"
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              onClick={handleToggle}
              className={`${
                isPaid ? "bg-blue-500" : "bg-yellow-500"
              } text-white font-bold py-2 px-4 rounded-full transition-colors duration-300`}
            >
              {isPaid ? "REN/PAID" : "SUB"}
            </button>
          </div>
        </div>

        <div className="w-full max-w-[350px] md:max-w-[800px] py-10 overflow-x-auto">
          {loading ? (
            <div className="text-center text-lg">Loading...</div>
          ) : error ? (
            <div className="text-center text-lg text-red-500">{error}</div>
          ) : (
            <>
              {isPaid ? (
                <table className="min-w-full border-collapse border border-gray-200">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border border-gray-300 px-4 py-2 text-left">
                        Hour
                      </th>
                      <th className="border border-gray-300 px-4 py-2 text-left">
                        REN
                      </th>
                      <th className="border border-gray-300 px-4 py-2 text-left">
                        SUB
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="border border-gray-300 px-4 py-2">
                          {item.HOUR}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {item.REN}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {item.SUB}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="flex flex-col lg:flex-row justify-center text-center gap-8">
                  <div className="w-full lg:w-1/2 border-2">
                    <table className="min-w-full border-collapse border border-gray-200">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="border border-gray-300 px-4 py-2 text-left">
                            Hour
                          </th>
                          <th className="border border-gray-300 px-4 py-2 text-left">
                            New-Sub
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index} className="hover:bg-gray-100">
                            <td className="border border-gray-300 px-4 py-2">
                              {item.HOUR}
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                              {item.newsub}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="w-full border-2">
                    <table className="min-w-full border-collapse border border-gray-200">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="border border-gray-300 px-4 py-2 text-left">
                            Hour
                          </th>
                          <th className="border border-gray-300 px-4 py-2 text-left">
                            Traffic
                          </th>
                          <th className="border border-gray-300 px-4 py-2 text-left">
                            Sent
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {traffic.map((item, index) => (
                          <tr key={index} className="hover:bg-gray-100">
                            <td className="border border-gray-300 px-4 py-2 text-left">
                              {item.HOUR}
                            </td>
                            <td className="border border-gray-300 px-4 py-2 text-left">
                              {item.traffic}
                            </td>
                            <td className="border border-gray-300 px-4 py-2 text-left">
                              {item.sent}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Hourly;
