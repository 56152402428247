import React, { useState, useEffect } from 'react';
import Layout from "../components/Layout";
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';  
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { trendApi } from '../Data/api';

const CheckHourlyLogs = () => {
    const [date, setDate] = useState(new Date());  // State for selected date
    const [data, setData] = useState([]);  // State to hold fetched data
    const [previousData, setPreviousData] = useState([]);  // State to hold previous data
    const [error, setError] = useState(null);  // Error state for API errors
    const [screenWidth, setScreenWidth] = useState(
        typeof window !== 'undefined' ? window.innerWidth : 1024
    );  

    // Helper function to format date to 'YYYY-MM-DD'
    const formatDate = (date) => {
        return date.toLocaleDateString('en-CA');
    };

    // Function to fetch data for selected date and previous 4 days
    const fetchData = async (selectedDate) => {
        setError(null);  
        const requests = [];

        for (let i = 0; i < 5; i++) {
            const currentDate = new Date(selectedDate);  
            currentDate.setDate(currentDate.getDate() - i);  
            const formattedDate = formatDate(currentDate);  

            requests.push(
                axios.post(trendApi, { dates: formattedDate }).then((response) => {
                    const renValues = response.data.results.find(item => item.date === formattedDate);
                    return {
                        date: formattedDate,
                        REN: renValues ? renValues.REN : [], // Handle undefined REN values
                    };
                })
            );
        }

        try {
            const results = await Promise.all(requests);
            const allData = results.flat();

            const uniqueData = Array.from(
                allData.reduce((map, item) => {
                    if (!map.has(item.date)) {
                        map.set(item.date, item);
                    }
                    return map;
                }, new Map()).values()
            );

            // Update state only if the new data is different from the previous data
            if (JSON.stringify(uniqueData) !== JSON.stringify(previousData)) {
                setPreviousData(data); 
                setData(uniqueData);  
            }
        } catch (err) {
            console.error("Error fetching data", err);
            setError("Error fetching data. Please try again later.");
        }
    };

    // Fetch data when the selected date changes
    useEffect(() => {
        fetchData(date);
    }, [date]);  

    // Refresh data every 10 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchData(date);  
        }, 10000); 

        return () => clearInterval(intervalId);  
    }, [date]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleResize = () => setScreenWidth(window.innerWidth);
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);

    const getHeaderText = (largeText, smallText) => screenWidth < 768 ? smallText : largeText;

    return (
        <Layout>
            <div className="w-full rounded p-5">
                <h1 className="bg-gray-800 p-5 rounded outline outline-violet-600 text-center font-bold text-2xl font-mono text-violet-500">
                    Tracking Trend
                </h1>

                {/* DatePicker for selecting date */}
                <div className="flex justify-center m-5">
                    <DatePicker
                        selected={date}  // Date from state
                        onChange={(newDate) => setDate(newDate)}  
                        dateFormat="yyyy-MM-dd"  
                        className="p-2 border border-gray-800 font-bold text-center rounded-lg"  
                    />
                </div>

                {/* Error state */}
                {error ? (
                    <div className="text-center text-lg text-red-500">{error}</div>
                ) : (
                    <div className="m-2 p-2">
                        <div className="overflow-x-auto text-center">
                            <DataTable value={data} className="border border-gray-400 shadow-lg">
                                <Column
                                    field="date"
                                    header="Date"
                                    bodyClassName="text-left font-mono font-bold text-gray-800 border border-gray-400"
                                />
                                <Column
                                    header={getHeaderText("12 am - 11 am", "12am to 11am")}
                                    body={(rowData) => rowData.REN[0] || 'N/A'}  
                                    bodyClassName="text-left font-mono font-bold text-gray-800 border border-gray-400"
                                />
                                <Column
                                    header={getHeaderText("11 am - 3 pm", "11am to 3pm")}
                                    body={(rowData) => rowData.REN[1] || 'N/A'} 
                                    bodyClassName="text-left font-mono font-bold text-gray-800 border border-gray-400"
                                />
                                <Column
                                    header={getHeaderText("3 pm - 7 pm", "3pm to 7pm")}
                                    body={(rowData) => rowData.REN[2] || 'N/A'}  
                                    bodyClassName="text-left font-mono font-bold text-gray-800 border border-gray-400"
                                />
                                <Column
                                    header={getHeaderText("Total REN", "Total-REN")}
                                    body={(rowData) => rowData.REN[3] || 'N/A'}  
                                    bodyClassName="text-left font-mono font-bold text-gray-800 border border-gray-400"
                                />
                            </DataTable>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default CheckHourlyLogs;
