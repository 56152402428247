import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { useNavigate } from 'react-router-dom';

const Log = () => {
  const [showModal, setShowModal] = useState(true); // Modal is shown by default
  const navigate = useNavigate();

  const handleLogout = () => {
    console.log('Logging out...');
    setShowModal(false);
    // Add your logout logic here
  };

  const handleCancel = () => {
    setShowModal(false);
    navigate('/Dailylogs');
  };

  const handleOk = () => {
    setShowModal(false);
    navigate('/'); // Navigate to home page on OK
  };

  useEffect(() => {
    // Optionally, you can add logic to automatically show modal here if needed
    setShowModal(true);
  }, []);

  return (
    <Layout>
      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-90">
            <h2 className="text-xl font-semibold mb-4 text-center">Are you sure you want to logout?</h2>
            <div className="flex justify-between mt-4">
            
              <button
                onClick={handleCancel}
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleOk}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Log;
