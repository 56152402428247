import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IoIosLogOut } from "react-icons/io";
import { BsArrowLeftCircle } from 'react-icons/bs'
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import HamburgerButton from './HamburgerMenuButton/HamburgerButton'
import { FaRegCalendarCheck } from "react-icons/fa";
import { FaHourglassEnd ,FaUserTimes } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { RiLogoutCircleLine } from "react-icons/ri";
import { FaUserAstronaut } from "react-icons/fa";
import { HiTrendingUp } from "react-icons/hi";
const Sidebar = () => {
  const [open, setOpen] = useState(true)
  const [mobileMenu, setMobileMenu] = useState(false)
  const location = useLocation()

  const Menus = [
    { title: 'Daily Revenue', path: '/Dailylogs', src:<FaRegCalendarCheck size={20} className="text-blue-700"/>},
    { title: 'MIS', path: '/mis-data', src:<MdOutlineMiscellaneousServices size={20} className="text-green-700"/>},
    { title: 'Hourly logs', path: '/one_hour', src:<FaHourglassEnd size={20} className="text-yellow-700"/>},
    { title: 'User logs', path: '/userLogs', src:<FaUserAstronaut size={20} className="text-green-700"/>},
    { title: 'BlackList User', path: '/black-list', src:<ImCross size={20} className="text-red-700"/>},
    { title: 'Unsubscribe User', path: '/unsub', src:<FaUserTimes  size={20} className="text-red-700"/>},
    { title: 'Trend', path: '/hourly-trend', src: <HiTrendingUp  size={20} className="text-green-700"/>},
    { title: 'Logout', path: '/logout', src:<RiLogoutCircleLine  size={20} className="text-red-700"/>},
  ]

  return (
    <>
      <div
      className={`${
        open ? 'w-60' : 'w-fit'
      } h-screen hidden sm:block relative duration-300 bg-gray-100 border-r border-gray-200 dark:border-gray-600 p-5 dark:bg-slate-700 shadow-y-xl`}
    >
        <BsArrowLeftCircle
          className={`${
            !open && 'rotate-180'
          } absolute text-3xl bg-white fill-slate-800  rounded-full cursor-pointer top-9 -right-4 dark:fill-gray-300 dark:bg-gray-800 `}
          onClick={() => setOpen(!open)}
        />
       
          <div className={`flex ${open && 'gap-x-4'} items-center`}>
              {/* < GiBubbles size={50} className="IoFootball animate-spin text-black/80 dark:text-white" style={{ animationDuration: '10s' }}/> */}
            {open && (
              <span className='text-xl font-bold whitespace-nowrap dark:text-white'>
              ADMIN PANNEL
              </span>
            )}
          </div>
  
       
        <ul className='pt-6'>
          {Menus.map((menu, index) => (
            <Link to={menu.path} key={index}>
                <hr/>
              <li
                className={`flex items-center gap-x-6 p-3 text-sm font-bold rounded-lg cursor-pointer dark:text-white hover:bg-gray-300 dark:hover:bg-gray-800
                        ${menu.gap ? 'mt-9' : 'mt-2'} ${
                  location.pathname === menu.path &&
                  'bg-black/20 dark:bg-gray-600'
                }`}
              >
              
                <span className='text-xl'>{menu.src}</span>
                <span
                  className={`${
                    !open && 'hidden'
                  } origin-left duration-300 hover:block`}
                >
                  {menu.title}
                </span>
              </li>
            </Link>
          ))}
        </ul>
      </div>
      {/* Mobile Menu */}
      <div className="">
        <HamburgerButton
          setMobileMenu={setMobileMenu}
          mobileMenu={mobileMenu}
        />
      </div>
      <div className="sm:hidden">
        <div
          className={`${
            mobileMenu ? 'flex' : 'hidden'
          } absolute z-50 flex-col items-center self-end py-8 mt-16 space-y-6 font-bold sm:w-auto left-6 right-6 dark:text-white  bg-gray-50 dark:bg-slate-800 drop-shadow md rounded-xl`}
        >
          {Menus.map((menu, index) => (
            <Link
              to={menu.path}
              key={index}
              onClick={() => setMobileMenu(false)}
            >
              <span
                className={` ${
                  location.pathname === menu.path &&
                  'bg-gray-200 dark:bg-gray-700'
                } p-2 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700`}
              >
                {menu.title}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}

export default Sidebar
// .hide-scrollbar {
//   -ms-overflow-style: none; /* Internet Explorer 10+ */
//   scrollbar-width: none; /* Firefox */
// }

// .hide-scrollbar::-webkit-scrollbar {
//   display: none; /* Safari and Chrome */
// }