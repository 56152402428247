import React, { useState, useEffect } from 'react';
import Layout from "../components/Layout";
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/saga-blue/theme.css'; // Choose your theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { dailyapi } from '../Data/api';
import moment from 'moment';  // Import moment.js

const CheckHourlyLogs = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    // Initial data fetch
    getData();

    // Set up an interval to auto-refresh the data every 5 minutes (300000 milliseconds)
    const interval = setInterval(() => {
      getData(false); // Fetch new data without triggering loading state
    }, 1000); // Refresh every 5 minutes

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const getData = async (showLoading = true) => {
    setError(null);   // Reset error before fetching

    try {
      const res = await axios.get(dailyapi);
      console.log("data", res.data);
      if (res.data && res.data.result) {
        setData(res.data.result); // Set the result array directly to data
      } else {
        setData([]); // Handle the case where no data is returned
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later."); // Set error message
      setData([]); // Clear data in case of error
    }
  };

  // Custom template to display today's day
  const todayDayTemplate = () => {
    return moment().format('YYYY-MM-DD'); // Example: 2024-09-09
  };

  return (
    <Layout>
    <div className='flex flex-col items-center bg-white'>
  <div className='w-full px-4'>
    <div className="text-3xl md:text-2xl font-extrabold py-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-600 tracking-wide text-center">
      Daily Logs
    </div>
  </div>

  <div className=' md:w-full w-[300px] px-4 py-6 md:py-10 overflow-x-auto'>
    {error ? (
      <div className="text-center text-lg text-red-500">{error}</div> // Show error message
    ) : (
      <DataTable value={data} className="w-full border-2">
        <Column className="border border-gray-300 px-2 md:px-4 py-2 text-left" header="Date" body={todayDayTemplate} />
        <Column field="Subscriptions" header="Subscriptions" className="border border-gray-300 px-2 md:px-4 py-2 text-left" />
        <Column field="paid_sub" header="Paid_Sub" className="border border-gray-300 px-2 md:px-4 py-2 text-left" />
        <Column field="total_paid_sub" header="Total_paid_sub" className="border border-gray-300 px-2 md:px-4 py-2 text-left" />
        <Column field="unsub" header="Unsub" className="border border-gray-300 px-2 md:px-4 py-2 text-left" />
        <Column field="REN" header="Ren" className="border border-gray-300 px-2 md:px-4 py-2 text-left" />
        <Column field="ren_revenue" header="Ren-Rev" className="border border-gray-300 px-2 md:px-4 py-2 text-left" />
        <Column field="sub_revenue" header="Sub-Rev" className="border border-gray-300 px-2 md:px-4 py-2 text-left" />
        <Column field="PAID_CR" header="Paid-Cr" className="border border-gray-300 px-2 md:px-4 py-2 text-left" />
        <Column field="total_revenue" header="Total_rev" className="border border-gray-300 px-2 md:px-4 py-2 text-left" />
      </DataTable>
    )}
  </div>
</div>

    </Layout>
  );
};

export default CheckHourlyLogs;
